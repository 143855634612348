import $ from 'jquery';

import factory from 'utils/factory';

import Module from 'modules/Module';
// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
    factory(Module, '.js-module');
});

require('popper.js');
require('bootstrap');
// Enable inline svgs in IE
// import svg4everybody from 'svg4everybody';

// svg4everybody();

$(document).ready(() => {
    factory(Module, '.js-module');
    var menuButton = $('.navbar-toggler');
    var menu = $('#MainNav');

    menuButton.unbind('click');
    menuButton.click(function() {
        // console.log(this);
        menu.slideToggle(200);
    });
    var popButton = $('.open--near-popup');
    popButton.unbind('click');
    popButton.click(function() {
        // console.log(this);
        var ref = $(this).data('ref');
        $('div.section__popup').hide();
        $('#' + ref).show();
        // $(this).parent().parent().parent().parent().parent().find('div.section__popup').show();
    });
    var xclosePopButton = $('.section__popup .close');
    xclosePopButton.unbind('click');
    xclosePopButton.click(function() {
        // console.log(this);
        $('div.section__popup').hide();
    });

});